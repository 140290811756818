'use client';

import { number, string } from 'prop-types';
import Image from 'next/image';

import { useSchemes } from '@personly/libs-providers';

const TechImage = ({ height, ext = 'svg', tech, width }) => {
  const { schemeHandle } = useSchemes();
  const src = `/images/techs/${tech}/${schemeHandle.toLowerCase()}.${ext}`;

  return (
    <div className={'flex justify-center'}>
      <Image alt={tech} src={src} height={height} width={width} />
    </div>
  );
};

TechImage.propTypes = {
  height: number.isRequired,
  ext: string,
  tech: string.isRequired,
  width: number.isRequired,
};

export default TechImage;
