import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/src/button/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/src/card-button/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/src/checkbox/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/src/file-source-upload/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/src/file-source/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/src/icon-select/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/src/masonry/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/src/select/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/src/text-upload/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/src/textarea/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/src/time-since/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/src/toaster/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/src/toggle-description/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/src/toggle/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-web/personly-web-corporate/src/components/brand-jumbotron/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-web/personly-web-corporate/src/components/brand-project/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-web/personly-web-corporate/src/components/brand-scheme/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/personly-web/personly-web-corporate/src/components/jumbotron/styles.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-web/personly-web-corporate/src/components/select-scheme/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-web/personly-web-corporate/src/components/tech-image/index.js");
