'use client';

import Image from 'next/image';
import { object } from 'prop-types';
import { useMemo } from 'react';

import { useLocalizedPathname } from '@personly/libs-hooks';
import { useSchemes } from '@personly/libs-providers';

const ProjectLogo = ({ languages }) => {
  const localizedPathname = useLocalizedPathname(languages);
  const { schemeHandle } = useSchemes();

  const href = useMemo(() => {
    const base = process.env.NEXT_PUBLIC_SPLASH_URL;
    return new URL(localizedPathname('/'), base).href;
  }, [localizedPathname]);

  return (
    <div className={'flex flex-row text-center justify-center items-center'}>
      <a href={href} className="flex flex-row">
        <Image
          src={`/images/brands/personly-logo.svg`}
          width={40}
          height={40}
          alt="personly"
        />
        &nbsp;&nbsp;
        <Image
          src={`/images/brands/personly-heading-${schemeHandle.toLowerCase()}.svg`}
          width={104}
          height={26}
          alt="personly"
        />
      </a>
    </div>
  );
};

ProjectLogo.propTypes = {
  languages: object.isRequired,
};

export default ProjectLogo;
