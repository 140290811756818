'use client';

import Image from 'next/image';
import { string } from 'prop-types';

import { useSchemes } from '@personly/libs-providers';

const BrandScheme = ({ alt }) => {
  const { schemeHandle } = useSchemes();
  const scheme = schemeHandle.toLowerCase();

  const src = `/images/brands/astateful-heading-${scheme}.svg`;

  return <Image alt={alt} width={101} height={26} src={src} />;
};

BrandScheme.propTypes = { alt: string.isRequired };

export default BrandScheme;
